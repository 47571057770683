import React, { useState, useEffect } from 'react'; // Asegúrate de importar useEffect también
import './App.css';
import { useSearchParams,useNavigate  } from 'react-router-dom'; // Importa useSearchParams
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {Adsense} from '@ctrl/react-adsense';

import soundFile from '../src/mp3/512.mp3';

function App() {
  // Utilizamos useParams para obtener el nombre de la familia de la URL.
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate(); // Hook para navegar
  let nombre = searchParams.get('n');

  const [isShared, setIsShared] = useState(false);

    // Estado para la cuenta regresiva
    const [countdown, setCountdown] = useState('');

    // Función para manejar el envío
    const handleShare = () => {
      const inputName = document.getElementById('shareText').value;
      if (inputName) {
        navigate(`/?n=${encodeURIComponent(inputName)}`);
        setIsShared(true); // Oculta el input y el botón "Ver" después de compartir
      } else {
        alert('Por favor, ingresa un nombre.');
      }
    };

    // Codifica el mensaje personalizado junto con la URL
    const mensajePersonalizado = encodeURIComponent(`*${nombre}* quiere enviarte este mensaje: `) + encodeURIComponent(window.location.href);
    const whatsappURL = `https://wa.me/?text=${mensajePersonalizado}`;


    useEffect(() => {

      

      // Función para calcular la cuenta regresiva
      const calculateCountdown = () => {
        const now = new Date();
        const newYear = new Date(`January 1, ${now.getFullYear() + 1} 00:00:00`);
        const timeLeft = newYear - now; // Diferencia en milisegundos
  
        // Calcular días, horas, minutos y segundos
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  
        // Actualizar el estado con la cuenta regresiva
        setCountdown(`Faltan ${days} días, ${hours} horas, ${minutes} minutos, ${seconds} segundos`);
      };
  
      // Calcular la cuenta regresiva cada segundo
      const intervalId = setInterval(calculateCountdown, 1000);
  
      // Limpieza del intervalo
      return () => clearInterval(intervalId);
    }, []);

  return (
    
    <div className="App" >
      <header className="App-header">
        <p>
          {nombre} <br />
          Te desea un <br />
          Año nuevo y Prospero <br />
          2024
        </p>
        {/* Muestra la cuenta regresiva aquí */}
        <p className="countdown">{countdown}</p>
      </header>
        <div className="Share-section">
        {!isShared && (
          <>
            <input id="shareText" type="text" placeholder="Tu nombre aqui" />
            <button onClick={handleShare}>Ver</button>
          </>
        )}

        {isShared && (
          <button onClick={() => window.open(whatsappURL, '_blank')} className="WhatsappShareButton">
            <FontAwesomeIcon icon={faWhatsapp} /> Compartir
          </button>
        )}
      </div>
      <div className="text-center adsbygoogle mt-2">
        <Adsense client="ca-pub-6526170761473746" slot="7259870550" />
      </div>
    </div>
  );
}

export default App;
